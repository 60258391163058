/* @jsx jsx */
import {jsx} from '@emotion/react'
import React, { useContext, useState, useEffect } from 'react'
import StoreContext from '@context/StoreContext'
import CartLineItem from '@molecules/CartLineItem';
import CartCouponCodeForm from "@molecules/CartCouponCodeForm";
import {
  cartWrapperCss,
  checkoutButtonCss,
  cartButtonWrapperCss,
  checkoutButtonWrapperCss,
  emptyCartCss,
  itemWrapperHeaderCss,
  priceWrapperCss,
  shippingInfoCss,
  discountWrapperCss,
  couponCodeTextCss,
  lineItemsWrapperCss,
} from './styles'
import IconDiscountSquare from "@icons/IconDiscountSquare";
import LoadingIndicator from "@molecules/LoadingIndicator";

const Cart = () => {

  const [loaded, setLoaded] = useState(false)

  const {
    removeCouponCodes,
    store: { client, checkout }
  } = useContext(StoreContext)

  useEffect(() => {
    if (typeof checkout.id !== 'undefined' && !loaded) {
      setLoaded(true)
    }
  }, [checkout])

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const handleRemoveCouponCode = () => {
    removeCouponCodes(client, checkout.id)
  }

  const lineItems = checkout.lineItems.map(item => <CartLineItem key={item.id.toString()} item={item} />)

  const subtotal = Intl.NumberFormat(undefined, {
    currency: 'USD',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(checkout.totalPrice)

  const getUsedCoupons = () => {
    if (checkout.discountApplications.length === 0) return []

    const appliedCoupons = [];

    checkout.discountApplications.forEach(app => {
      const code = (
        <>
          -{Intl.NumberFormat(undefined, {
          currency: app.value.currencyCode,
          minimumFractionDigits: 2,
          style: 'currency',
        }).format(app.value.amount)}
        <span css={couponCodeTextCss}> {app.code}</span>
        </>
      )
      appliedCoupons.push(code)
    })

    return appliedCoupons
  }

  return !loaded ? (<LoadingIndicator/>) : (
    <>
      <div css={cartWrapperCss}>
        <div css={lineItemsWrapperCss}>
          <div css={itemWrapperHeaderCss} className={'line-item'}>
            <p>&nbsp;</p>
            <p>Product</p>
            <p>&nbsp;</p>
            <p>Quantity</p>
            <p>Price</p>
            <p>Total</p>
          </div>
          {lineItems}
        </div>

        {checkout.lineItems && checkout.lineItems.length === 0 && (
          <h3 css={emptyCartCss}>Your cart is empty.</h3>
        )}

        {checkout.lineItems && checkout.lineItems.length > 0 && (
          <div css={checkoutButtonWrapperCss}>

            <div>
              {checkout.discountApplications && checkout.discountApplications.length > 0 && (
                <div css={discountWrapperCss}>
                  {<IconDiscountSquare/>}
                  <div>
                    <p>Discounts Applied:</p>
                    {getUsedCoupons().map((coupon, index) => <p key={`coupon-${index}`}>{coupon}</p>)}
                  </div>
                  <div>
                    <button onClick={handleRemoveCouponCode}>Remove</button>
                  </div>
                </div>
              )}
              <div css={priceWrapperCss}>
                <h2>SUBTOTAL</h2>
                <p>{subtotal}</p>
              </div>
              <p css={shippingInfoCss}>Shipping & taxes calculated at checkout</p>
            </div>

            <div>
              <CartCouponCodeForm />
              <div css={cartButtonWrapperCss}>
                <button
                  onClick={e => handleCheckout(e)}
                  disabled={checkout.lineItems.length === 0}
                  css={checkoutButtonCss}
                >
                  Check out
                </button>
              </div>
            </div>

          </div>
        )}
      </div>
    </>
  )
}

export default Cart




import React from 'react'

const IconDiscountSquare = ({className}) => (
  <svg
    viewBox='0 0 24 24'
    width='1em'
    height='1em'
    focusable='false'
    aria-hidden='true'
    role='presentation'
    className={className}
  >
    <g className='discount-square_svg__group0'>
      <path
        className='discount-square_svg__path0'
        d='M15.116 7.116l-8 8a1.25 1.25 0 101.767 1.768l8-8a1.25 1.25 0 00-1.767-1.768z'
      />
      <circle
        className='discount-square_svg__circle0'
        cx={8}
        cy={8.135}
        r={2.25}
      />
      <circle
        className='discount-square_svg__circle1'
        cx={16}
        cy={16.135}
        r={2.25}
      />
      <path
        className='discount-square_svg__path1'
        d='M2.913 22l-.652.031a.25.25 0 01-.261-.25v-.694a1 1 0 00-2 0v.956A1.958 1.958 0 001.956 24h.957a1 1 0 100-2z'
      />
      <path
        className='discount-square_svg__path2'
        d='M23 20.087a1 1 0 00-1 1l.031.651a.251.251 0 01-.069.184.247.247 0 01-.181.078h-.7a1 1 0 000 2h.957A1.959 1.959 0 0024 22.043v-.956a1 1 0 00-1-1z'
      />
      <path
        className='discount-square_svg__path3'
        d='M2.913 0h-.957A1.958 1.958 0 000 1.957v.956a1 1 0 002 0l-.032-.651a.251.251 0 01.069-.184A.247.247 0 012.218 2h.695a1 1 0 000-2z'
      />
      <path
        className='discount-square_svg__path4'
        d='M21.086 2l.652-.031a.251.251 0 01.184.069.247.247 0 01.078.181v.694a1 1 0 102 0v-.956A1.959 1.959 0 0022.043 0h-.957a1 1 0 000 2z'
      />
      <path
        className='discount-square_svg__path5'
        d='M23 5.5a1 1 0 00-1 1V10a1 1 0 002 0V6.5a1 1 0 00-1-1z'
      />
      <path
        className='discount-square_svg__path6'
        d='M23 13a1 1 0 00-1 1v3.5a1 1 0 002 0V14a1 1 0 00-1-1z'
      />
      <path
        className='discount-square_svg__path7'
        d='M1 11a1 1 0 001-1V6.5a1 1 0 00-2 0V10a1 1 0 001 1z'
      />
      <path
        className='discount-square_svg__path8'
        d='M0 17.5a1 1 0 002 0V14a1 1 0 00-2 0z'
      />
      <path
        className='discount-square_svg__path9'
        d='M6.5 2H10a1 1 0 000-2H6.5a1 1 0 000 2z'
      />
      <path
        className='discount-square_svg__path10'
        d='M17.5 0H14a1 1 0 000 2h3.5a1 1 0 000-2z'
      />
      <path
        className='discount-square_svg__path11'
        d='M10 22H6.5a1 1 0 000 2H10a1 1 0 000-2z'
      />
      <path
        className='discount-square_svg__path12'
        d='M17.5 22H14a1 1 0 000 2h3.5a1 1 0 000-2z'
      />
    </g>
  </svg>
)

export default IconDiscountSquare

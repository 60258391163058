import {css} from '@emotion/react'
import {fonts, colors, unitConverter} from '@utils/base'

export const loadingCss = css`
  padding: ${unitConverter('100px')};
  font-family: ${fonts.tektonPro};
  font-size: 2em;
  color: ${colors.white};
  h2 {
    text-align: center;
  }
`

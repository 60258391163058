import {colors, fonts, fontWeights, transitions, breakpoints, unitConverter} from "@utils/base";
import {css} from '@emotion/react'

export const cartWrapperCss = css`
  color: ${colors.white};
  background-color: rgba(0,0,0,.5);

  p {
    margin: 0;
    font-family: ${fonts.tektonPro};
  }
`;

export const priceWrapperCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-family: ${fonts.signo};

  h2, p {
    font-weight: ${fontWeights.normal};
    font-family: ${fonts.signo};
  }

  h2 {
    padding-right: 20px;
  }

  p {
    font-size: 1.2em;
  }
`

export const checkoutButtonCss = css`
  padding: ${unitConverter('10px 79px')};
  border: none;
  border-radius: ${unitConverter('5px')};
  background-color: ${colors.lightGray};
  color: ${colors.darkGray};
  border: ${unitConverter('1px')} solid ${colors.darkGray};
  transition: all ${transitions.fast} ease-in-out;
  outline: none;
  font-family: ${fonts.signo};
  font-size: .9em;
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: ${colors.lightGrayHover};
  }
`

export const itemWrapperHeaderCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: ${unitConverter('10px 20px 10px')};
  margin-bottom: ${unitConverter('10px')};
  color: ${colors.white};
  align-items: center;
  justify-content: space-around;
  font-family: ${fonts.tektonPro};
  font-size: 1.2em;
  background-color: ${colors.darkGray};

  p {
    margin: 0;
    width: calc(100% / 6);

    &:nth-of-type(4),
    &:nth-of-type(5) {
      text-align: center;
    }

    &:nth-of-type(6) {
      text-align: right;
    }

    ${breakpoints.tabletPortrait} {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        display: none;
      }
    }
 }
`;

export const checkoutButtonWrapperCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: ${unitConverter('20px')};
  border-top: ${unitConverter('1px')} solid ${colors.lightGray};
  padding: ${unitConverter('40px 20px')};
  transition: all 1s ease-in-out !important;

  > div {
    display: inline-block;
  }

  ${breakpoints.tabletPortrait} {
    flex-direction: column;

    > div:first-of-type {
      margin-bottom: ${unitConverter('40px')};
    }
  }
`

export const shippingInfoCss = css`
  font-size: 1.2em;
  color: ${colors.lightGray};
`

export const cartButtonWrapperCss = css`
  margin-top: ${unitConverter('10px')};
`

export const emptyCartCss = css`
  margin: ${unitConverter('80px;')};
  text-align: center;
  font-size: 2em;
  font-family: ${fonts.tektonPro};
`

export const discountWrapperCss = css`
  display: flex;
  align-items: center;
  margin-bottom: ${unitConverter('20px')};

  > div {
    &:first-of-type {
      margin-right: ${unitConverter('40px')};
    }
  }

  svg {
    fill: ${colors.white};
    margin-right: ${unitConverter('10px')};
    width: ${unitConverter('30px')};
    height: ${unitConverter('30px')};
    fill: ${colors.lightGray};
  }

  button {
    padding: ${unitConverter('5px 10px')};
    border: none;
    border-radius: ${unitConverter('5px')};
    background-color: ${colors.darkGray};
    color: ${colors.lightGray};
    border: ${unitConverter('1px')} solid ${colors.darkGray};
    transition: all ${transitions.fast} ease-in-out;
    outline: none;
    font-family: ${fonts.signo};
    font-size: ${unitConverter('12px')};
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: ${colors.darkGrayHover};
      color: ${colors.lightGrayHover};
    }
  }
`

export const couponCodeTextCss = css`
  color: ${colors.lightGray};
  margin-left: ${unitConverter('10px')};
`

export const lineItemsWrapperCss = css`
  ${breakpoints.tabletPortrait} {

  }
`

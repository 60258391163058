import {css} from '@emotion/react'
import {breakpoints, colors, fonts, transitions, unitConverter} from '@utils/base'

export const wrapperCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: ${unitConverter('10px 20px 10px')};
  color: ${colors.white};
  align-items: center;
  justify-content: space-between;
  font-family: ${fonts.tektonPro};
  font-size: 1.2em;

  div {
    margin: 0;
    text-align: left;
    width: calc(100% / 6);

    &:nth-of-type(4),
     &:nth-of-type(5){
      text-align: center;
    }

    &:nth-of-type(6) {
      text-align: right;
    }

    ${breakpoints.tabletPortrait} {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        display: none;
      }

      &:nth-of-type(2),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        width: 25%;
        font-size: 85%;
      }
    }
  }

  button {
    display: block;
    margin: ${unitConverter('10px auto 0')};
    padding: ${unitConverter('10px')};
    border: none;
    border-radius: ${unitConverter('5px')};
    background-color: ${colors.darkGray};
    color: ${colors.lightGray};
    border: ${unitConverter('1px')} solid ${colors.darkGray};
    transition: all ${transitions.fast} ease-in-out;
    outline: none;
    font-family: ${fonts.signo};
    font-size: ${unitConverter('12px')};
    cursor: pointer;

    &:hover {
      background-color: ${colors.darkGrayHover};
      color: ${colors.lightGrayHover};
    }
  }
`

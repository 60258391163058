/* @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/react'
import {loadingCss} from './styles'

const LoadingIndicator = () => (
  <div css={loadingCss}>
    <h2>Loading &hellip;</h2>
  </div>
)

export default LoadingIndicator

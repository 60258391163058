/* @jsx jsx */
import React, { useState, useContext } from 'react'
import {css, jsx} from '@emotion/react'
import {colors, breakpoints, unitConverter} from '@utils/base'
import StoreContext from '@context/StoreContext'


const cartQuantityFormCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: ${unitConverter('100px')};
  margin: 0 auto;

  ${breakpoints.tabletPortrait} {
    flex-direction: column;
    align-items: center;

    button {
      &:first-of-type {
        order: 3;
      }
      &:last-of-type {
        order: 1;
      }
    }

    input {
      order: 2;
    }
  }

  input {
    padding: ${unitConverter('5px')};
    margin-top: ${unitConverter('10px')};
    width: ${unitConverter('40px')};
    text-align: center;
    background-color: transparent;
    color: ${colors.text};
    border: none;
    font-size: .9em;
    outline: none;
  }
`

const CartQuantityForm = ({item}) => {
  const {
    updateLineItem,
    store: { client, checkout }
  } = useContext(StoreContext)

  const [quantity, setQuantity] = useState(item.quantity)

  const increment = (e) => {
    e.preventDefault()
    setQuantity(quantity + 1)
    updateLineItem(client, checkout.id, item.id, quantity + 1)
  }

  const decrement = (e) => {
    e.preventDefault()
    if (quantity != 1) {
      setQuantity(quantity - 1)
      updateLineItem(client, checkout.id, item.id, quantity - 1)
    }
  }

  const handleChange = (e) => {
    if (e.target.value >= 1) {
      const val = parseInt(e.target.value);
      if (!isNaN(val)) {
        setQuantity(val)
        updateLineItem(client, checkout.id, item.id, val)
      }
    }
  }

  return (
    <>
      <form action={''} method={'post'} onSubmit={e => handleSubmit(e)} css={cartQuantityFormCss}>
        <button onClick={decrement} disabled={quantity === 1}>-</button>
        <input type={'text'} value={quantity} size={'4'} onChange={e => handleChange(e)} />
        <button onClick={increment}>+</button>
      </form>
    </>
  )
}

export default CartQuantityForm

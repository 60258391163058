/* @jsx jsx */
import React, { useContext } from 'react'
import StoreContext from '@context/StoreContext'
import {jsx} from '@emotion/react'
import { Link } from 'gatsby'
import CartQuantityForm from "@molecules/CartQuantityForm";
import {wrapperCss} from './styles'

const CartLineItem = ({item, ...props}) => {
  const {
    removeLineItem,
    store: { client, checkout }
  } = useContext(StoreContext)

  const variantImage = item.variant.image ?  (
    <img
      src={item.variant.image.src}
      alt={item.title}
      height={'60px'}
    />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map((option, index) => <p key={`option-${index}`}>{option.name}: {option.value}</p>)
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id, item.title)
  }

  const price = Intl.NumberFormat(undefined, {
    currency: item.variant.priceV2.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(item.variant.price)

  const priceAtQuantity = Intl.NumberFormat(undefined, {
    currency: item.variant.priceV2.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(item.variant.price * item.quantity)

  return (
    <>
      <div css={wrapperCss} className={'line-item'}>
        <div>
          <Link to={`/product/${item.variant.product.handle}/`} data-testid={`item-image-link`}>
            {variantImage}
          </Link>
        </div>
        <div>
          {item.title}
          {` `}
          {item.variant.title === !'Default Title' ? item.variant.title : ''}
        </div>
        <div>
          {selectedOptions}
        </div>
        <div>
          <CartQuantityForm item={item} />
          <button onClick={handleRemove}>Remove</button>
        </div>
        <div>
          {price}
        </div>
        <div>
          {priceAtQuantity}
        </div>
      </div>
    </>
  )
}

export default CartLineItem

/* @jsx jsx */
import {css, jsx} from '@emotion/react'
import {graphql} from 'gatsby'
import Header from '@components/organisms/header'
import ContentWrapper from '@components/molecules/contentWrapper'
import Heading1 from '@components/atoms/headings/heading1/heading1'
import Layout from '@containers/layout'
import GraphQLErrorList from '@components/graphql-error-list'
import get from 'lodash.get'
import {breakpoints, unitConverter} from '@utils/base'
import Cart from '@organisms/Cart'

const CartPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const {seo, global, header, footer} = site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const contentWrapperCss = css`
    h1  {
      margin-bottom: ${unitConverter('40px')};
      font-size: 2em;

      ${breakpoints.tabletPortrait} {
        text-align: center;
      }
    }
  `

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Header title={seo.title} {...header} logoWidth={400} showSearch={false} />
      <ContentWrapper css={contentWrapperCss}>
        <Heading1>Shopping Cart</Heading1>
        <Cart />
      </ContentWrapper>
    </Layout>
  )
}

export default CartPage

export const query = graphql`
  query CartPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
      title
      description
      keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fixed {
              base64
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
  }
`

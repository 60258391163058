/* @jsx jsx */
import React, { useContext, useRef } from 'react'
import {css, jsx} from '@emotion/react'
import { unitConverter, colors, transitions, fonts } from '@utils/base'
import searchBg from '@img/search-bg.jpg'
import StoreContext from '@context/StoreContext'
import NotificationContext from '@context/NotificationContext'

const couponFormCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  max-width: ${unitConverter('300px')};

  input {
    line-height: 0;

    &:first-of-type {
      width: calc(80% - ${unitConverter('10px')});
      padding: ${unitConverter('5px 10px')};
      font-family: ${fonts.signo};
      border: 1px solid ${colors.lightGray};
      background: black url(${searchBg}) 0 0 repeat-x;
      color: ${colors.white};
      font-size: .8em;
      margin-right: ${unitConverter('10px')};
      outline: none;
      text-align: left;
    }

    &:last-of-type {
      display: block;
      width: 20%;
      padding: ${unitConverter('15px 0')};
      border: none;
      border-radius: ${unitConverter('5px')};
      background-color: ${colors.darkGray};
      color: ${colors.lightGray};
      border: ${unitConverter('1px')} solid ${colors.darkGray};
      transition: all ${transitions.fast} ease-in-out;
      outline: none;
      font-family: ${fonts.signo};
      font-size: ${unitConverter('12px')};
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background-color: ${colors.darkGrayHover};
        color: ${colors.lightGrayHover};
      }
    }
  }
`

const CartCouponCodeForm = () => {

  const {
    addCouponCode,
    store: { client, checkout }
  } = useContext(StoreContext)

  const couponCodeRef = useRef()

  const handleCouponSubmit = (e) => {
    e.preventDefault();
    addCouponCode(client, checkout.id, couponCodeRef.current.value)
    couponCodeRef.current.value = ''
  }

  return (
    <>
      <form action='' method="post" onSubmit={handleCouponSubmit} css={couponFormCss}>
        <input type={'text'} size="50" name={'coupon'} placeholder={'Coupon Code'} ref={couponCodeRef} data-testid="coupon-input" />
        <input type={'submit'} value={'Apply'} />
      </form>
    </>
  )
}

export default CartCouponCodeForm
